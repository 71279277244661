/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // toggles hamburger and nav open and closed states
        $(".testimonial-carousel").slick();
        // $('.single-item').slick();
               var removeClass = true;
               $(".hamburger").click(function () {
                   $(".hamburger").toggleClass('is-active');
                   $("#sideNav").toggleClass('sideNav-open');
                   $(".sideNavBody").toggleClass('sideNavBody-push');
                   removeClass = false;
               });

               $(".sideNav").click(function() {
                   removeClass = false;
               });

               $("html").click(function () {
                   if (removeClass) {
                       $(".hamburger").removeClass('is-active');
                       $("#sideNav").removeClass('sideNav-open');
                       $(".sideNavBody").removeClass('sideNavBody-push');
                   }
                   removeClass = true;
               });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {
        document.initMap = function() {
          var map_lat = parseFloat($('.map-lat').data('maplat'));
          var map_long = parseFloat($('.map-lng').data('maplng'));
          var point_lat = parseFloat($('.point-lat').data('pointlat'));
          var point_long = parseFloat($('.point-lng').data('pointlng'));
          var address = $('.address').data('address');
          var title = $('.title').data('title');
          var directions = $('.directions').data('directions');
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          var isDraggable = w > 480 ? true : false;
          var point = new google.maps.LatLng(point_lat, point_long);
          var settings = {
            zoom: 15,
            center: {lat: map_lat, lng: map_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP};
          var map = new google.maps.Map(document.getElementById("map_canvas"), settings);
          var contentString = '<div id="content" style="text-align:left;">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<a href="' + directions + '" target="_blank"><h4>' + title + '<br><small>(Click for directions)</small></h4></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';
          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title
          });

          infowindow.open(map,companyMarker);

          var center;
          function calculateCenter() {
            center = map.getCenter();
          }
          google.maps.event.addDomListener(map, 'idle', function() {
            calculateCenter();
          });
          google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
          });
        };

        var maps_api_key = 'AIzaSyCbWGO_wnZXRRP1HCDbCt5OSwPJs0nS0XE';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
